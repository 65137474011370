import './__styles__/Temples.scss';

import React from 'react';

import {useNavigate, useOutletContext} from "react-router-dom";
import {Card} from "primereact/card";

import templeHeaderImage from "../assets/images/temple-header.png";
import {Button} from "primereact/button";
import {ScrollPanel} from "primereact/scrollpanel";
import {COLLECTION} from "../data/globals";

function Temples() {
    const navigate = useNavigate();

    const [ user ]: any = useOutletContext();

    const templeHeader = (
        <img alt="Temple Header" src={templeHeaderImage} />
    );

    const templeFooter = (
        <div className="grid p-3 justify-content-between gap-2">
            <small className="col-5">
                Members: 11
            </small>
            <small className="col-5">
                Classes: 4
            </small>
        </div>
    );

    const templeMemberships = user && user.templeMembershipsDetails
        && user.templeMembershipsDetails.map((temple: any, index: React.Key | null | undefined) => {
            return ( temple &&
                <>
                    <div key={index} className="col-12 md:col-6 lg:col-4 cursor-pointer scalein">
                        <Card className="temple-overview" title={temple[COLLECTION.TEMPLES.NAME]}
                              subTitle={<><Button label="ENTER" className="p-button-secondary p-button-outlined" /><br/><br/>{temple[COLLECTION.TEMPLES.DEITY_NAME]}</>}
                              header={templeHeader} footer={templeFooter} onClick={() => navigate(`/temple/${temple._id}`)}>
                            <ScrollPanel style={{ width: '100%', height: '130px' }}>
                                <p className="m-0">
                                    {temple.intro}
                                    <br/>
                                    <span>...know more at <a href={temple[COLLECTION.TEMPLES.WEBSITE_URL]} target="_blank">temple's official website</a></span>
                                </p>
                            </ScrollPanel>
                        </Card>
                    </div>
                </>
        );
    });

    return (
        <>
            <br/>
            Hare Krishna <span className="font-bold">{user && user.db? (user.db[COLLECTION.DEVOTEES.NAME] || 'Deva') : 'Deva'}</span>
            <br/>
            You are member of following temple(s):
            <div className="central-stage">
                <div className="grid p-3 justify-content-center">
                    { templeMemberships }
                </div>
            </div>
        </>
    );
}
export default Temples;