import React, {useState} from 'react';
import {InputText} from "primereact/inputtext";
import {COLLECTION, FIELDS, LOGIN_MODE} from "../../data/globals";
import {useFormik} from "formik";
import {Button} from "primereact/button";
import _ from "lodash";
import {ScrollPanel} from "primereact/scrollpanel";
import {Fieldset} from "primereact/fieldset";
import {classNames} from "primereact/utils";

function Settings(props:any) {
    const formik = useFormik({
        initialValues: props.userDB,
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {
            formik.validateForm();
        }
    });
    const isFormFieldInvalid = (name: string | number) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: string | number) => {
        // @ts-ignore
        return isFormFieldInvalid(name) ? <small className="p-error">{_.get(formik.errors, name)}</small> : '';
    };

    const save = () => {
        props.submitAction(formik.values, props.userID, props.self);
        formik.resetForm();
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <ScrollPanel style={{ width: '100%', height: 'auto', maxHeight: '60vh' }}>
                <Fieldset legend={<span>Login Options<i className="pi pi-sign-in has-margin-sides"></i></span>} toggleable className="has-no-padding has-no-margin">
                    <div className="grid">
                        <div className="col-7 p-inputgroup">
                                <span className={classNames('p-inputgroup-addon', {'form-field-error-icon': isFormFieldInvalid(COLLECTION.DEVOTEES.EMAIL) })}>
                                    <i className="pi pi-at"></i>
                                </span>
                            <span className="p-float-label">
                                    <InputText id={COLLECTION.DEVOTEES.EMAIL} maxLength={90} disabled
                                               value={formik.values[COLLECTION.DEVOTEES.EMAIL]}
                                               onChange={(e) => {
                                                   formik.setFieldValue(COLLECTION.DEVOTEES.EMAIL, e.target.value);
                                               }}
                                               className={classNames({ 'p-invalid': isFormFieldInvalid(COLLECTION.DEVOTEES.EMAIL) })} />
                                    <label className="capitalize"
                                           htmlFor={COLLECTION.DEVOTEES.EMAIL}>{getFormErrorMessage(COLLECTION.DEVOTEES.EMAIL) || 'Email'}</label>
                                </span>
                        </div>
                        <div className="col-5 p-inputgroup">
                            {   formik.values[COLLECTION.DEVOTEES.EMAIL_VERIFIED]?
                                <span className="p-inputgroup-addon border-0" title={'Verified'}>
                                    <i className="pi pi-check-circle text-green-500"></i>
                                </span>
                                :
                                <Button label="Verify" className="float-right p-button-primary" type="button" onClick={save}/>
                            }
                        </div>
                        <div className="col-7 p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-phone"></i>
                                </span>
                            <span className="p-float-label">
                                    <InputText id={COLLECTION.DEVOTEES.PHONE_NUMBER} maxLength={14}
                                               value={formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER]} />
                                    <label className="capitalize"
                                           htmlFor={COLLECTION.DEVOTEES.PHONE_NUMBER}>{COLLECTION.DEVOTEES.PHONE_NUMBER.replaceAll('_', ' ')}</label>
                                </span>
                        </div>
                        <div className="col-5 p-inputgroup">
                            {   formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER_VERIFIED]?
                                <span className="p-inputgroup-addon border-0" title={'Verified'}>
                                    <i className="pi pi-check-circle text-green-500"></i>
                                </span>
                                :
                                <Button label="Verify" className="float-right p-button-primary" type="button" onClick={save}/>
                            }
                        </div>
                    </div>
                </Fieldset>
            </ScrollPanel>
            <div className="grid">
                <div className="col-9 has-margin-top-bottom">
                    <small className="float-left"><i className="pi pi-check-circle text-green-500"></i> means Verified.</small>
                </div>
            </div>
        </form>
    );
}
export default Settings;
