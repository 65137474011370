export const SKILLS = [
  "Coding/ Software Development",
  "Graphic Designing",
  "Rangoli",
  "Flower Decoration",
  "Cooking",
  "Driving",
  "Teaching",
  "Dancing",
  "Singing",
  "Musical Instruments Playing",
  "Event Management",
  "Public Relations",
  "Printing/ Flex and Banners",
  "Coding/ Frontend",
  "Coding/ Backend",
  "Coding/ Database",
  "Coding/ Full Stack"
]