import './__styles__/TempleDetails.scss';

import React, {useEffect, useState} from 'react';
import {MenuItem} from "primereact/menuitem";
import {BreadCrumb} from "primereact/breadcrumb";
import {useOutletContext, useParams} from "react-router-dom";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {COLLECTION} from "../data/globals";
function TempleDetails() {
    let { templeID } = useParams();

    const [ user ]: any = useOutletContext();

    const [templeDetails, setTempleDetails] = useState<any | null>();

    const items: MenuItem[] = [{ label: templeDetails? templeDetails[COLLECTION.TEMPLES.NAME] : 'Temple' } ];
    const home: MenuItem = { icon: 'pi pi-home', url: '/' }

    const initializeTemple = () => {
        let details = user && user.templeMembershipsDetails && user.templeMembershipsDetails.filter((temple:any) => {
            return temple._id == templeID;
        });
        // there will be only one entry for the current temple selected
        if (details && Array.isArray(details) && details.length === 1) {
            setTempleDetails(details[0]);
        }
    }

    const classFooter = (
        <>
            <Button id="btn-sign-in" label="Add Devotee to this class" className="p-button-help p-button-outlined"
                    icon="pi pi-user-plus"/>
            <div className="grid p-2 justify-content-between gap-2">
                <small className="col-5">
                    Members: 11
                </small>
                <small className="col-6">
                    Leader: HG Gaurlila Das Pr.
                </small>
            </div>
        </>
    );

    useEffect(() => {
        initializeTemple();
    }, [user]);

    return (
        <div>
            {   templeDetails && (
                <>
                    <BreadCrumb className="center" model={items} home={home} />
                    <span className="central-stage has-margin">
                        <div className="grid p-1 justify-content-center gap-2 width-full">
                            <Card className="col-4 cursor-pointer scalein" title="Class of Vishal Nagar" subTitle="@Achal Leela Khushboo"
                                  footer={classFooter}>
                                <p className="m-0">

                                </p>
                            </Card>
                            <Card className="col-4 cursor-pointer scalein" title="Class of Pimple Saudagar" subTitle="@La Vida Loca"
                                  footer={classFooter}>
                                <p className="m-0">

                                </p>
                            </Card>
                            <Card className="col-1 cursor-pointer scalein" title="Add New" subTitle="Class"
                                  footer="">
                                <p className="m-0">
                                    <span className="pi pi-plus"></span>
                                </p>
                            </Card>
                        </div>
                    </span>
                </>
            )}
        </div>
    );
}
export default TempleDetails;
