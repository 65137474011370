import React, {useEffect, useRef, useState} from 'react';
import './__styles__/Login.scss';
import { Button } from "primereact/button";
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';


import {
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signInWithPopup
} from "firebase/auth";
import {LOCAL_STORAGE_VARS} from "../data/globals";

import loginHeader from '../assets/images/devotee_association.jpg';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useNavigate} from "react-router-dom";
import {Divider} from "primereact/divider";
import {Tag} from "primereact/tag";
import {Badge} from "primereact/badge";

async function signInWithGoogle() {
    // Sign in Firebase using popup auth and Google as the identity provider.
    let provider = new GoogleAuthProvider();
    await signInWithPopup(getAuth(), provider);
}

function Login() {
    const navigate = useNavigate();
    const auth = getAuth();
    auth.useDeviceLanguage();

    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [authInProgress, setAuthInProgress] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>('IN');
    const [code, setCode] = useState<string>();
    const [otpSent, setOtpSent] = useState<boolean>(false);
    const [confirmOtp, setConfirmOtp] = useState<any>();

    const msgs:any = useRef(null);

    // Initialize firebase auth
    const initFirebaseAuth = () => {
        // Listen to auth state changes.
        onAuthStateChanged(getAuth(), authStateObserver);
    }

    // Triggers when the auth state change for instance when the user signs-in or signs-out.
    const authStateObserver = (user: any) => {
        if(user) {
            navigate("/");
        }
    }

    function getOTP() {
        if (!phoneNumber || !parseInt(phoneNumber) || phoneNumber.length < 12) {
            showErrorMessage(undefined, 'Error: Enter valid Mobile Number', '');
            return;
        }
        setAuthInProgress(true);
        const appVerifier = new RecaptchaVerifier('btn-sign-in', {
            'size': 'invisible',
            'callback': (response:any) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber
                onSignInSubmit();
            }
        }, auth);
        if (phoneNumber) {
            signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent successfully. Now, prompt user to type the code from the message,
                    // then sign the user with confirmationResult.confirm(code).
                    setConfirmOtp(confirmationResult);
                    setOtpSent(true);
                })
                .catch((error) => {
                    // Error; SMS not sent
                    showErrorMessage(error, error.code, '');
                })
                .finally(() => {
                    setAuthInProgress(false);
                });
        }
    }

    function verifyOTP() {
        if (code && confirmOtp) {
            setAuthInProgress(true);
            confirmOtp.confirm(code)
                .then((result: any) => {
                    console.log(result.user);
                    // User signed in successfully.
                    const user = result.user;
                    if (user.phoneNumber) {
                        localStorage.setItem(LOCAL_STORAGE_VARS.PHONE_NUMBER, user.phoneNumber);
                    }
                    navigate("/");
                }).catch((error: any) => {
                    // User couldn't sign in (bad verification code?)
                    showErrorMessage(error, error.code, '');
                })
                .finally(() => {
                    setAuthInProgress(false);
                });
        }
    }

    function onSignInSubmit() {
        console.log("onSignInSubmit");
    }

    function showErrorMessage(error: any, summary: string, detail: string) {
        console.log(error);
        msgs.current.show([
            { sticky: false, severity: 'error', summary: summary, detail: detail},
        ]);
    }

    const subTitleMobile = (
        <>
            {
                otpSent?
                    (<><span className="pi pi-info-circle"></span>&nbsp;&nbsp;OTP sent to mobile +{phoneNumber}</>)
                :
                    (<><span className="pi pi-lock"></span>&nbsp;&nbsp;Login with your Mobile Number</>)
            }
        </>
    );
    const header = (
        <img alt="Card" src={loginHeader} />
    );
    const footerMobile = (
        <div className="flex flex-wrap justify-content-center gap-2">
            <Button label="Back" className="p-button-outlined"
                    icon="pi pi-arrow-left" loading={authInProgress}
                    onClick={() => setInitialLoad(true)}/>
            <Button id="btn-sign-in" label={otpSent? "Verify OTP": "Get OTP"}
                    icon={otpSent? "pi pi-unlock": "pi pi-mobile"} loading={authInProgress}
                    onClick={otpSent? verifyOTP:getOTP}/>
            <small className="has-padding text-align-left">
                • We recommend using your <strong>Whatsapp registered mobile number</strong>
                <br/>
                • To verify we will send <strong>OTP via SMS</strong> to your mobile. Message & data rates may apply
                <br/><Divider type="solid" />
                <span className="float-right">
                    <a className="has-padding" href="">Terms of Service</a>
                    <a href="">Privacy Policy</a>
                </span>
            </small>
        </div>
    );

    const subTitleEmailOptions = (
        <>
            Please login to continue.
        </>
    );
    const footerEmailOptions = (
        <div className="flex flex-wrap justify-content-center gap-2">
            <small className="has-padding text-align-left">
                • We recommend using <strong>Google registered email</strong> to login.
                <br/>
                • But you can also <strong>Login using your mobile number</strong> using OTP (One Time Password).
                <br/>
                <br/><Divider type="solid" />
                <span className="float-right">
                    <a className="has-padding" href="">Terms of Service</a>
                    <a href="">Privacy Policy</a>
                </span>
            </small>
        </div>
    );

    useEffect(() => {
        initFirebaseAuth();
    }, []);

    return (
        <div className="card flex justify-content-center has-padding central-stage">
            {
                initialLoad ? (
                    <Card title="Devotee Association (DevA)" subTitle={subTitleEmailOptions} footer={footerEmailOptions} header={header}
                          className="md:w-25rem">
                        <div>
                            <Button id="btn-sign-in" label="Login with Google" className="p-button-danger"
                                    icon="pi pi-google" loading={authInProgress}
                                    onClick={signInWithGoogle}/>
                            <Divider type="solid" align="center">
                                <Tag>OR</Tag>
                            </Divider>
                            <Button id="btn-sign-in" label="Login with Mobile Number" className="p-button-outlined"
                                    icon="pi pi-mobile" loading={authInProgress}
                                    onClick={() => setInitialLoad(false)}/>
                        </div>
                        <Messages ref={msgs} />
                    </Card>
                ) : (
                    <Card title="Devotee Association (DevA)" subTitle={subTitleMobile} footer={footerMobile} header={header}
                          className="md:w-25rem">
                        <div className="center">
                            {
                                otpSent?
                                    <span className="p-float-label">
                                <InputText id="otp-input" className="width-full" maxLength={6} value={code} keyfilter="int"
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)} />
                                <label htmlFor="otp-input">OTP received via SMS</label>
                            </span>
                                    :
                                    <PhoneInput
                                        inputClass="phone-input"
                                        inputProps={{
                                            name: 'Mobile Number',
                                            required: true
                                        }}
                                        country={'in'}
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}/>
                            }
                        </div>
                        <Messages ref={msgs} />
                    </Card>
                )
            }
        </div>
    );
}
export default Login;
