import React, {useState} from 'react';
import {InputText} from "primereact/inputtext";
import {useFormik} from "formik";
import {COLLECTION} from "../../data/globals";
import _ from "lodash";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import PhoneInput from "react-phone-input-2";
import {collection, getDocs, getFirestore, limit, query, where} from "firebase/firestore";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import {Divider} from "primereact/divider";

async function getDevoteeDetailsByPhoneNumber(phoneNumber: string) {
    const database = getFirestore();
    const docRef = query(collection(database, COLLECTION.DEVOTEES._NAME), where(COLLECTION.DEVOTEES.PHONE_NUMBER, "==", phoneNumber), limit(2));
    const querySnapshot = await getDocs(docRef);
    return querySnapshot;
}

function AddNewDevotee(props: any) {
    const [mobileAlreadyRegisteredError, setMobileAlreadyRegisteredError] = useState<string>();

    const formik = useFormik({
        initialValues: props.newUserDB,
        validate: (data) => {
            let errors = {};

            if (!data[COLLECTION.DEVOTEES.PHONE_NUMBER]) {
                _.set(errors, COLLECTION.DEVOTEES.PHONE_NUMBER, 'Phone Number is required');
            } else if (data[COLLECTION.DEVOTEES.PHONE_NUMBER].length < 12) {
                _.set(errors, COLLECTION.DEVOTEES.PHONE_NUMBER, 'Invalid Phone Number');
            }

            if (!data[COLLECTION.DEVOTEES.NAME]) {
                _.set(errors, COLLECTION.DEVOTEES.NAME, 'Name is required');
            }

            return errors;
        },
        onSubmit: (data) => {
            getDevoteeDetailsByPhoneNumber(data[COLLECTION.DEVOTEES.PHONE_NUMBER]).then(querySnapshot => {
                if (querySnapshot && !querySnapshot.empty) {
                    querySnapshot.forEach(document => {
                        const message = 'Phone Number already registered under name: ' + document.data()[COLLECTION.DEVOTEES.NAME];
                        _.set(formik.errors, COLLECTION.DEVOTEES.PHONE_NUMBER, message);
                        setMobileAlreadyRegisteredError(message);
                    });
                } else {
                    addDevoteeConfirm(data);
                    //props.submitAction(data);
                    //formik.resetForm();
                }
            })
        }
    });
    const isFormFieldInvalid = (name: string | number) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: string | number) => {
        // @ts-ignore
        return isFormFieldInvalid(name) ? <small className="p-error">{_.get(formik.errors, name)}</small> : '';
    };

    const confirmationMessage = (
        <span>
            You will be adding following person as a new devotee to this platform.
            <br/>Are you sure?
            <br/><br/>
            Phone: <span className="text-primary">{formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER]}</span>
            <br/>
            Name: {formik.values[COLLECTION.DEVOTEES.NAME]}
            <Divider />
            <small>Note: This Phone Number will be used to login to this platform. It can't be changed later.</small>
        </span>
    )

    const addDevoteeConfirm = (data: any) => {
        confirmDialog({
            message: confirmationMessage,
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            accept() {
                props.submitAction(data);
            }
        });
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <ConfirmDialog />
            <div className="grid has-margin">
                <div className="col-12 md:col-6 lg:col-6 p-inputgroup">
                    <span className={classNames('p-inputgroup-addon', {'form-field-error-icon': isFormFieldInvalid(COLLECTION.DEVOTEES.PHONE_NUMBER) })}>
                        <i className="pi pi-phone"></i>
                    </span>
                    <span className="p-float-label">
                        <PhoneInput
                            inputClass="phone-input"
                            inputProps={{
                                name: COLLECTION.DEVOTEES.PHONE_NUMBER,
                                required: true
                            }}
                            country={'in'}
                            value={formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER]}
                            onChange={(phoneNumber) => {
                                formik.setFieldValue(COLLECTION.DEVOTEES.PHONE_NUMBER, `+${phoneNumber}`);
                                setMobileAlreadyRegisteredError('');
                            }}/>
                    </span>
                </div>
                <div className="col-12 md:col-6 lg:col-6 p-inputgroup">
                    <span className={classNames('p-inputgroup-addon', {'form-field-error-icon': isFormFieldInvalid(COLLECTION.DEVOTEES.NAME) })}>
                        <i className="pi pi-user"></i>
                    </span>
                    <span className="p-float-label">
                        <InputText id={COLLECTION.DEVOTEES.NAME} required maxLength={60}
                                   value={formik.values[COLLECTION.DEVOTEES.NAME]}
                                   onChange={(e) => {
                                       formik.setFieldValue(COLLECTION.DEVOTEES.NAME, e.target.value);
                                   }}
                                   className={classNames({ 'p-invalid': isFormFieldInvalid(COLLECTION.DEVOTEES.NAME) })} />
                        <label htmlFor={COLLECTION.DEVOTEES.NAME}>{getFormErrorMessage(COLLECTION.DEVOTEES.NAME) || 'Name'}</label>
                    </span>
                </div>
                <div className="col-12">
                    {getFormErrorMessage(COLLECTION.DEVOTEES.PHONE_NUMBER)}
                </div>
                {/*<div className="col-12">
                    <small className="p-error">{mobileAlreadyRegisteredError}</small>
                </div>*/}
            </div>
            <Button label={`Add ${formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER]}`} icon="pi pi-user-plus" className="float-right p-button-danger" type="submit"/>
        </form>
    );
}
export default AddNewDevotee;
