import React from 'react';
import Error404Image from '../assets/images/Error404.png';
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
function Error404() {
    return (
        <div>
            <img src={Error404Image} width='75%'/>
            <h1>Hare Krishna... Oops ! </h1>
            <p>You are looking from something not available, or it could be us.<br/>Go to Home Page.
            </p>
            <Link to="/" className="router-link-button">
                <Button id="btn-sign-in" label="Home Page" className="p-button-raised p-button-lg p-button-secondary"
                        icon="pi pi-home"/>
            </Link>
        </div>
    );
}
export default Error404;
