import React, {useState} from 'react';
import {InputText} from "primereact/inputtext";
import {COLLECTION, FIELDS, LOGIN_MODE} from "../../data/globals";
import { Dropdown } from 'primereact/dropdown';
import {useFormik} from "formik";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import _ from "lodash";
import {ScrollPanel} from "primereact/scrollpanel";
import {Calendar} from "primereact/calendar";
import {Fieldset} from "primereact/fieldset";
import {Checkbox} from "primereact/checkbox";
import {AutoComplete} from "primereact/autocomplete";
import {Chip} from "primereact/chip";
import {SKILLS} from "../../data/skills";
import {CITIES} from "../../data/cities";
import ALL_CITIES from "../../data/states.json";
import {AREAS} from "../../data/areas";

function UserProfile(props:any) {
    const [filteredSkills, setFilteredSkills] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const [filteredAreas, setFilteredAreas] = useState([]);

    const formik = useFormik({
        initialValues: props.userDB,
        validate: (data) => {
            let errors = {};

            if (!data[COLLECTION.DEVOTEES.NAME]) {
                _.set(errors, COLLECTION.DEVOTEES.NAME, 'Name is required');
            }
            if (data[COLLECTION.DEVOTEES.EMAIL] && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data[COLLECTION.DEVOTEES.EMAIL])) {
                _.set(errors, COLLECTION.DEVOTEES.EMAIL, 'Invalid Email');
            }

            return errors;
        },
        onSubmit: (data) => {
            formik.validateForm();
        }
    });
    const isFormFieldInvalid = (name: string | number) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: string | number) => {
        // @ts-ignore
        return isFormFieldInvalid(name) ? <small className="p-error">{_.get(formik.errors, name)}</small> : '';
    };

    const save = () => {
        props.submitAction(formik.values, props.userID, props.self);
        formik.resetForm();
    }

    const skillsSearch = (event: any) => {
        let _filteredSkills:any;
        if (!event.query.trim().length) {
            _filteredSkills = SKILLS;
        }
        else {
            _filteredSkills = SKILLS.filter((skill) => {
                return skill.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        setFilteredSkills(_filteredSkills);
    }

    const citiesSearch = (event: any) => {
        let _filteredCities:any;
        if (!event.query.trim().length) {
            _filteredCities = CITIES;
        }
        else {
            _filteredCities = CITIES.filter((city) => {
                return city.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }
        setFilteredCities(_filteredCities);
    }
    const areasSearch = (event: any) => {
        let _filteredAreas: any;
        const city: string = _.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.CITY}`) || '';
        if (city && _.get(AREAS, city.toUpperCase())) {
            if (!event.query.trim().length) {
                _filteredAreas = _.get(AREAS, city.toUpperCase());
            } else {
                _filteredAreas = _.get(AREAS, city.toUpperCase()).filter((area: string) => {
                    return area.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredAreas(_filteredAreas);
        }
    }
    const setValidState = (selectedCity: string) => {
        if (selectedCity) {
            let validCity:any = ALL_CITIES.filter((city: any) => {
                return city.city.toLowerCase() === selectedCity.toLowerCase();
            });
            console.log(validCity);
            if (validCity && Array.isArray(validCity) && validCity[0]) {
                _.set(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.STATE}`, validCity[0].state);
                console.log(formik.values);
            }
        } else {
            _.set(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.STATE}`, '');
        }
    }

    const templeMemberships = props.templeMemberships
        && props.templeMemberships.map((temple: any, index: React.Key | null | undefined) => {
            return ( temple &&
                <Chip key={index} className="pl-0 pr-3 has-margin" template={
                    <>
                        <span className="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">T</span>
                        <span className="ml-3">{temple[COLLECTION.TEMPLES.NAME]}</span>
                    </>
                } />
            );
        });

    const classMemberships = props.classMemberships
        && props.classMemberships.map((temple: any, index: React.Key | null | undefined) => {
            return ( temple &&
                <Chip key={index} className="pl-0 pr-3 has-margin" template={
                    <>
                        <span className="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">T</span>
                        <span className="ml-3">{temple[COLLECTION.CLASSES.NAME]}</span>
                    </>
                } />
            );
        });

    return (
        <form onSubmit={formik.handleSubmit}>
            <ScrollPanel style={{ width: '100%', height: 'auto', maxHeight: '60vh' }}>
                <Fieldset legend={<span>Personal Details<i className="pi pi-user-edit has-margin-sides"></i></span>} toggleable className="has-no-padding has-no-margin">
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-6 p-inputgroup">
                            <span className={classNames('p-inputgroup-addon', {'form-field-error-icon': isFormFieldInvalid(COLLECTION.DEVOTEES.EMAIL) })}>
                                <i className="pi pi-at"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={COLLECTION.DEVOTEES.EMAIL} maxLength={90} disabled
                                           value={formik.values[COLLECTION.DEVOTEES.EMAIL]}
                                           onChange={(e) => {
                                               formik.setFieldValue(COLLECTION.DEVOTEES.EMAIL, e.target.value);
                                           }}
                                           className={classNames({ 'p-invalid': isFormFieldInvalid(COLLECTION.DEVOTEES.EMAIL) })} />
                                <label className="capitalize"
                                       htmlFor={COLLECTION.DEVOTEES.EMAIL}>{getFormErrorMessage(COLLECTION.DEVOTEES.EMAIL) || 'Email'}</label>
                            </span>
                            {   formik.values[COLLECTION.DEVOTEES.EMAIL_VERIFIED] &&
                                <span className="p-inputgroup-addon border-0" title={'Verified'}>
                                    <i className="pi pi-check-circle text-green-500"></i>
                                </span>
                            }
                        </div>
                        <div className="col-12 md:col-6 lg:col-6 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-phone"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={COLLECTION.DEVOTEES.PHONE_NUMBER} disabled maxLength={14}
                                           value={formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER]} />
                                <label className="capitalize"
                                       htmlFor={COLLECTION.DEVOTEES.PHONE_NUMBER}>{COLLECTION.DEVOTEES.PHONE_NUMBER.replaceAll('_', ' ')}</label>
                            </span>
                            {   formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER_VERIFIED] &&
                                <span className="p-inputgroup-addon border-0" title={'Verified'}>
                                    <i className="pi pi-check-circle text-green-500"></i>
                                </span>
                            }
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className={classNames('p-inputgroup-addon', {'form-field-error-icon': isFormFieldInvalid(COLLECTION.DEVOTEES.NAME) })}>
                                <i className="pi pi-user"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={COLLECTION.DEVOTEES.NAME} required maxLength={60}
                                           value={formik.values[COLLECTION.DEVOTEES.NAME]}
                                           onChange={(e) => {
                                               formik.setFieldValue(COLLECTION.DEVOTEES.NAME, e.target.value);
                                           }}
                                           className={classNames({ 'p-invalid': isFormFieldInvalid(COLLECTION.DEVOTEES.NAME) })} />
                                <label className="capitalize"
                                       htmlFor={COLLECTION.DEVOTEES.NAME}>{getFormErrorMessage(COLLECTION.DEVOTEES.NAME) || 'Name'}</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-sun"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={COLLECTION.DEVOTEES.INITIATED_NAME} maxLength={90}
                                           value={formik.values[COLLECTION.DEVOTEES.INITIATED_NAME]}
                                           onChange={(e) => {
                                               formik.setFieldValue(COLLECTION.DEVOTEES.INITIATED_NAME, e.target.value);
                                           }} />
                                <label htmlFor={COLLECTION.DEVOTEES.INITIATED_NAME}>Initiated Name</label>
                            </span>
                            {/*{getFormErrorMessage(COLLECTION.DEVOTEES.INITIATED_NAME)}*/}
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-calendar"></i>
                            </span>
                            <span className="p-float-label">
                                <Calendar
                                    inputId={COLLECTION.DEVOTEES.DOB}
                                    name={COLLECTION.DEVOTEES.DOB}
                                    dateFormat="dd/mm/yy" maxDate={new Date()}
                                    value={ formik.values[COLLECTION.DEVOTEES.DOB] && !Date.parse(formik.values[COLLECTION.DEVOTEES.DOB]) ?
                                        formik.values[COLLECTION.DEVOTEES.DOB].toDate() : ''}
                                    onChange={(e) => {
                                        formik.setFieldValue(COLLECTION.DEVOTEES.DOB, e.target.value);
                                    }}
                                />
                                <label htmlFor={COLLECTION.DEVOTEES.DOB}>Date of Birth</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-heart"></i>
                            </span>
                            <span className="p-float-label">
                                <Dropdown id={COLLECTION.DEVOTEES.GENDER} showClear
                                          value={formik.values[COLLECTION.DEVOTEES.GENDER]}
                                          options={[
                                              { name: 'Female', code: 'F' },
                                              { name: 'Male', code: 'M' },
                                              { name: 'Unspecified', code: 'U' }
                                          ]}
                                          optionLabel="name" optionValue="code"
                                          onChange={(e) => {
                                              formik.setFieldValue(COLLECTION.DEVOTEES.GENDER, e.value);
                                          }}
                                          placeholder="You identify with" />
                                <label htmlFor={COLLECTION.DEVOTEES.GENDER}>Gender</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-heart-fill"></i>
                            </span>
                            <span className="p-float-label">
                                <Dropdown id={COLLECTION.DEVOTEES.MARITAL_STATUS} showClear
                                          value={formik.values[COLLECTION.DEVOTEES.MARITAL_STATUS]}
                                          options={[
                                              { name: 'Single', code: 'S' },
                                              { name: 'Married', code: 'M' },
                                              { name: 'Others', code: 'O' }
                                          ]}
                                          optionLabel="name" optionValue="code"
                                          onChange={(e) => {
                                              formik.setFieldValue(COLLECTION.DEVOTEES.MARITAL_STATUS, e.value);
                                          }}
                                          placeholder="Your Status" />
                                <label htmlFor={COLLECTION.DEVOTEES.MARITAL_STATUS}>Marital Status</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-language"></i>
                            </span>
                            <span className="p-float-label">
                                <Dropdown id={COLLECTION.DEVOTEES.LANG_PREF} showClear
                                          value={formik.values[COLLECTION.DEVOTEES.LANG_PREF]}
                                          options={[
                                              { name: 'Hindi', code: 'H' },
                                              { name: 'English', code: 'E' },
                                              { name: 'Marathi', code: 'M' },
                                              { name: 'Others', code: 'O' }
                                          ]}
                                          optionLabel="name" optionValue="code"
                                          onChange={(e) => {
                                              formik.setFieldValue(COLLECTION.DEVOTEES.LANG_PREF, e.value);
                                          }}
                                          placeholder="Preferred Class" />
                                <label htmlFor={COLLECTION.DEVOTEES.LANG_PREF}>Language Preference</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-briefcase"></i>
                            </span>
                            <span className="p-float-label">
                                <Dropdown id={COLLECTION.DEVOTEES.OCCUPATION} showClear
                                          value={formik.values[COLLECTION.DEVOTEES.OCCUPATION]}
                                          options={[
                                              { name: 'Service', code: 'Service' },
                                              { name: 'Business', code: 'Business' },
                                              { name: 'Professional', code: 'Professional' },
                                              { name: 'Others', code: 'Others' }
                                          ]}
                                          optionLabel="name" optionValue="code"
                                          onChange={(e) => {
                                              formik.setFieldValue(COLLECTION.DEVOTEES.OCCUPATION, e.value);
                                          }}
                                          placeholder="Your occupation" />
                                <label htmlFor={COLLECTION.DEVOTEES.OCCUPATION}>Occupation</label>
                            </span>
                        </div>
                    </div>
                </Fieldset>
                <br/>
                <hr style={{ color: 'lightgray' }}/>
                <Fieldset legend={<span>Address<i className="pi pi-map-marker has-margin-sides"></i></span>} toggleable collapsed className="has-no-padding has-no-margin">
                    <div className="grid">
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-home"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={FIELDS.ADDRESS.LINE1} maxLength={90}
                                           value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.LINE1}`)}
                                           onChange={(e) => {
                                               formik.setFieldValue(`${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.LINE1}`, e.target.value);
                                           }} />
                                <label htmlFor={FIELDS.ADDRESS.LINE1}>Line 1</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-building"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={FIELDS.ADDRESS.SOCIETY} maxLength={90}
                                           value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.SOCIETY}`)}
                                           onChange={(e) => {
                                               formik.setFieldValue(`${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.SOCIETY}`, e.target.value);
                                           }} />
                                <label htmlFor={FIELDS.ADDRESS.SOCIETY}>Residential Society</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-truck"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={FIELDS.ADDRESS.LINE2} maxLength={90}
                                           value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.LINE2}`)}
                                           onChange={(e) => {
                                               formik.setFieldValue(`${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.LINE2}`, e.target.value);
                                           }} />
                                <label htmlFor={FIELDS.ADDRESS.LINE2}>Line 2</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-th-large"></i>
                            </span>
                            <span className="p-float-label">
                                <AutoComplete id={FIELDS.ADDRESS.CITY} forceSelection
                                              value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.CITY}`)}
                                              suggestions={filteredCities}
                                              completeMethod={citiesSearch}
                                              onChange={(e) => {
                                                  formik.setFieldValue(`${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.CITY}`, e.value);
                                                  setValidState(e.target.value);
                                              }} />
                                <label htmlFor={FIELDS.ADDRESS.CITY}>City</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map-marker"></i>
                            </span>
                            <span className="p-float-label">
                                <AutoComplete id={FIELDS.ADDRESS.AREA} disabled={!_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.CITY}`)}
                                              value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.AREA}`)}
                                              suggestions={filteredAreas}
                                              completeMethod={areasSearch}
                                              onChange={(e) => formik.setFieldValue(`${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.AREA}`, e.value)} />
                                <label htmlFor={FIELDS.ADDRESS.AREA}>Area</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-box"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={FIELDS.ADDRESS.PINCODE} maxLength={6} keyfilter="int"
                                           value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.PINCODE}`)}
                                           onChange={(e) => {
                                               formik.setFieldValue(`${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.PINCODE}`, e.target.value);
                                           }} />
                                <label htmlFor={FIELDS.ADDRESS.PINCODE}>Pin Code</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-table"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={FIELDS.ADDRESS.STATE} disabled
                                           value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.STATE}`)} />
                                <label htmlFor={FIELDS.ADDRESS.STATE}>State</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-flag"></i>
                            </span>
                            <span className="p-float-label">
                                <Dropdown id={FIELDS.ADDRESS.COUNTRY} showClear
                                          value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.COUNTRY}`)}
                                          options={[
                                              { name: 'India', code: 'India' },
                                              { name: 'Others', code: 'Others' }
                                          ]}
                                          optionLabel="name" optionValue="code"
                                          onChange={(e) => {
                                              formik.setFieldValue(`${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.COUNTRY}`, e.value);
                                          }}
                                          placeholder="Your country" />
                                <label htmlFor={FIELDS.ADDRESS.COUNTRY}>Country</label>
                            </span>
                        </div>
                        <div className="col-12 md:col-4 lg:col-3 p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText id={FIELDS.ADDRESS.GMAP_URL} maxLength={90}
                                           value={_.get(formik.values, `${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.GMAP_URL}`)}
                                           onChange={(e) => {
                                               formik.setFieldValue(`${COLLECTION.DEVOTEES.ADDRESS}.${FIELDS.ADDRESS.GMAP_URL}`, e.target.value);
                                           }} />
                                <label htmlFor={FIELDS.ADDRESS.GMAP_URL}>Google Maps URL</label>
                            </span>
                        </div>
                    </div>
                </Fieldset>
                <br/>
                <hr style={{ color: 'lightgray' }}/>
                <Fieldset legend={<span>Skills (as many as possible)<i className="pi pi-wrench has-margin-sides"></i></span>} toggleable collapsed className="has-no-padding has-no-margin">
                    <div className="col-12 p-inputgroup">
                        <span className="p-float-label">
                            <AutoComplete id={COLLECTION.DEVOTEES.SKILLS} multiple
                                          value={formik.values[COLLECTION.DEVOTEES.SKILLS]}
                                          suggestions={filteredSkills}
                                          completeMethod={skillsSearch}
                                          onChange={(e) => formik.setFieldValue(COLLECTION.DEVOTEES.SKILLS, e.value)} />
                            <label htmlFor={COLLECTION.DEVOTEES.SKILLS}>Start typing and choose skills from list, one by one</label>
                        </span>
                    </div>
                </Fieldset>
                <br/>
                <hr style={{ color: 'lightgray' }}/>
                <Fieldset legend={<span>Communication Preferences<i className="pi pi-megaphone has-margin-sides"></i></span>} toggleable collapsed className="has-no-padding has-no-margin">
                    <div className="col-12 md:col-6 lg:col-6 p-inputgroup">
                        <Checkbox
                            id={COLLECTION.DEVOTEES.WHATSAPP_CONSENT}
                            name={COLLECTION.DEVOTEES.WHATSAPP_CONSENT}
                            checked={formik.values[COLLECTION.DEVOTEES.WHATSAPP_CONSENT]}
                            onChange={(e) => {
                                formik.setFieldValue(COLLECTION.DEVOTEES.WHATSAPP_CONSENT, e.checked);
                            }}
                        ></Checkbox>&nbsp;&nbsp;
                        <span>I provide my consent to be communicated via WhatsApp.</span>
                    </div>
                    <div className="col-12 md:col-4 lg:col-4 p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-mobile"></i>
                        </span>
                        <span className="p-float-label">
                            <InputText id={COLLECTION.DEVOTEES.WHATSAPP_NUMBER} maxLength={14} keyfilter="int" disabled={!formik.values[COLLECTION.DEVOTEES.WHATSAPP_CONSENT]}
                                       value=
                                           {
                                               !formik.values[COLLECTION.DEVOTEES.WHATSAPP_CONSENT]? '':
                                                   (formik.values[COLLECTION.DEVOTEES.WHATSAPP_NUMBER] || formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER])
                                           }
                                       onChange={(e) => {
                                           formik.setFieldValue(COLLECTION.DEVOTEES.WHATSAPP_NUMBER, e.target.value);
                                       }} />
                            <label htmlFor={COLLECTION.DEVOTEES.WHATSAPP_NUMBER}>WhatsApp Phone Number</label>
                        </span>
                    </div>
                </Fieldset>
                <br/>
                <hr style={{ color: 'lightgray' }}/>
                <Fieldset legend={<span>Memberships<i className="pi pi-id-card has-margin-sides"></i></span>} toggleable collapsed className="has-no-padding has-no-margin">
                    <div className="grid">
                        <div className="col-12">
                            Temples: {templeMemberships}
                        </div>
                        <div className="col-12">
                            Classes: {classMemberships}
                        </div>
                    </div>
                </Fieldset>
                <br/>
                <hr style={{ color: 'lightgray' }}/>
                <Fieldset legend={<span>For official purposes<i className="pi pi-lock has-margin-sides"></i></span>} toggleable collapsed className="has-no-padding has-no-margin">
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-6">
                            Counsellor ID: {formik.values[COLLECTION.DEVOTEES.COUNSELLOR_ID]}
                        </div>
                        <div className="col-12 md:col-6 lg:col-6">
                            Source: {formik.values[COLLECTION.DEVOTEES.SOURCE]}
                        </div>
                    </div>
                </Fieldset>
            </ScrollPanel>
            <div className="grid">
                <div className="col-9 has-margin-top-bottom">
                    <small className="float-left"><strong>Note: Phone Number</strong> and <strong>Email</strong> can only be updated from User <i className="pi pi-cog"></i> Settings Page because they need verification.</small>
                </div>
                <div className="col-3 has-margin-top-bottom">
                    <Button label="Save" icon="pi pi-save" className="float-right p-button-danger" type="button" onClick={save}/>
                </div>
            </div>
        </form>
    );
}
export default UserProfile;
