import React, {useState} from 'react';
import {InputText} from "primereact/inputtext";
import {useFormik} from "formik";
import {COLLECTION} from "../../data/globals";
import _ from "lodash";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import PhoneInput from "react-phone-input-2";
import {collection, getDocs, getFirestore, limit, query, where} from "firebase/firestore";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import {Divider} from "primereact/divider";

async function getDevoteeDetailsByPhoneNumber(phoneNumber: string) {
    const database = getFirestore();
    const docRef = query(collection(database, COLLECTION.DEVOTEES._NAME), where(COLLECTION.DEVOTEES.PHONE_NUMBER, "==", phoneNumber), limit(2));
    const querySnapshot = await getDocs(docRef);
    return querySnapshot;
}

function ViewDevoteeDetails(props: any) {
    const [mobileNotRegisteredError, setMobileNotRegisteredError] = useState<string>();
    const [userMayWantToAddNewDevotee, setUserMayWantToAddNewDevotee] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: props.userDB,
        validate: (data) => {
            let errors = {};

            if (!data[COLLECTION.DEVOTEES.PHONE_NUMBER]) {
                _.set(errors, COLLECTION.DEVOTEES.PHONE_NUMBER, 'Phone Number is required');
            } else if (data[COLLECTION.DEVOTEES.PHONE_NUMBER].length < 12) {
                _.set(errors, COLLECTION.DEVOTEES.PHONE_NUMBER, 'Invalid Phone Number');
            }

            return errors;
        },
        onSubmit: (data) => {
            getDevoteeDetailsByPhoneNumber(data[COLLECTION.DEVOTEES.PHONE_NUMBER]).then(querySnapshot => {
                if (querySnapshot && !querySnapshot.empty) {
                    querySnapshot.forEach(document => {
                        const userDb = document.data();
                        userDb.id = document.id;
                        formik.resetForm();
                        props.submitAction(userDb);
                    });
                } else {
                    const message = `Phone Number ${data[COLLECTION.DEVOTEES.PHONE_NUMBER]} is Not Registered yet.`;
                    _.set(formik.errors, COLLECTION.DEVOTEES.PHONE_NUMBER, message);
                    setMobileNotRegisteredError(message);
                    setUserMayWantToAddNewDevotee(true);
                }
            })
        }
    });
    const isFormFieldInvalid = (name: string | number) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name: string | number) => {
        // @ts-ignore
        return isFormFieldInvalid(name) ? <small className="p-error">{_.get(formik.errors, name)}</small> : '';
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <ConfirmDialog />
            <div className="grid has-margin">
                <div className="col-12 md:col-6 lg:col-6 p-inputgroup">
                    <span className={classNames('p-inputgroup-addon', {'form-field-error-icon': isFormFieldInvalid(COLLECTION.DEVOTEES.PHONE_NUMBER) })}>
                        <i className="pi pi-phone"></i>
                    </span>
                    <span className="p-float-label">
                        <PhoneInput
                            inputClass="phone-input"
                            inputProps={{
                                name: COLLECTION.DEVOTEES.PHONE_NUMBER,
                                required: true
                            }}
                            country={'in'}
                            value={formik.values[COLLECTION.DEVOTEES.PHONE_NUMBER]}
                            onChange={(phoneNumber) => {
                                formik.setFieldValue(COLLECTION.DEVOTEES.PHONE_NUMBER, `+${phoneNumber}`);
                                setMobileNotRegisteredError('');
                            }}/>
                    </span>
                </div>
                <div className="col-12">
                    {getFormErrorMessage(COLLECTION.DEVOTEES.PHONE_NUMBER)}
                </div>
            </div>
            { userMayWantToAddNewDevotee && <Button label="Add Devotee" icon="pi pi-user-plus" className="float-left p-button-danger" onClick={props.addNewDevotee}/>}
            <Button label="Get Details" icon="pi pi-user" className="float-right p-button-danger" type="submit"/>
        </form>
    );
}
export default ViewDevoteeDetails;
