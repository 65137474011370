import './__styles__/Home.scss';

import React, {useEffect, useRef, useState} from 'react';

import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";

import {Outlet, useNavigate} from "react-router-dom";

import defaultProfilePic from '../assets/images/devotee-user-icon.gif';
import chantingAnimationGif from '../assets/images/chanting-animation.gif';
import logo from "../assets/images/logo.png";

import { Menubar } from 'primereact/menubar';
import {MenuItem} from 'primereact/menuitem';
import {Avatar} from "primereact/avatar";
import {Toast, ToastSeverityType} from "primereact/toast";
import {MessageSeverity} from "primereact/api";
import {ProgressBar} from "primereact/progressbar";
import messages from "../data/messages.json";
import {collection, doc, getDoc, getDocs, getFirestore, limit, query, setDoc, addDoc, where} from "firebase/firestore";
import {COLLECTION, LOGIN_MODE} from "../data/globals";
import {Dialog} from "primereact/dialog";
import {Menu} from "primereact/menu";
import {classNames} from "primereact/utils";
import UserProfile from "../components/form/UserProfile";
import AddNewDevotee from "../components/form/AddNewDevotee";
import ViewDevoteeDetails from "../components/form/ViewDevoteeDetails";
import _ from "lodash";
import Settings from "../components/form/Settings";

function Home() {
    const firestore = getFirestore();
    const navigate = useNavigate();
    const [user, setUser] = useState<any | null>();
    const [loginMode, setLoginMode] = useState<string>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<any | null>(null);

    const userProfileActionsPanel = useRef<any>(null);
    const toast = useRef<Toast>(null);

    // Initialize firebase auth
    const initFirebaseAuth = () => {
        // Listen to auth state changes.
        onAuthStateChanged(getAuth(), authStateObserver);
    }

    // Triggers when the auth state change for instance when the user signs-in or signs-out.
    const authStateObserver = (user: any) => {
        if(!user) {
            navigate("/login");
        } else {
            setUser(user);
            getUserMemberships(user);
        }
    }

    async function getDataFromReference(dataReference: any) {
        const docSnap = await getDoc(dataReference);
        if (docSnap.exists()) {
            return docSnap;
        } else {
            // doc.data() will be undefined in this case
            if (dataReference._key.path.segments) {
                throw new Error(dataReference._key.path.segments.reduce((result: string, segment: string) => result + "/" + segment) + " does not exist.");
            } else {
                throw new Error("Error reading Data from FireStore");
            }
        }
    }
    async function getDevoteeDetailsByPhoneNumber(database: any, phoneNumber: string) {
        const docRef = query(collection(database, COLLECTION.DEVOTEES._NAME), where(COLLECTION.DEVOTEES.PHONE_NUMBER, "==", phoneNumber), limit(2));
        const querySnapshot = await getDocs(docRef);

        if (querySnapshot && !querySnapshot.empty) {
            if (querySnapshot.size > 1) {
                throw new Error(messages.ERROR_USER_MULTPLE_ENTRIES);
            } else {
                return querySnapshot;
            }
        } else {
            const newUser = {
                'phone_number': phoneNumber,
                'email': '',
                'temple_memberships': [doc(firestore, 'temples', 'ZhLpyM9s6Uo9B8WwDzk4')]
            }
            await addNewUser(newUser, true);
        }
    }

    async function getDevoteeDetailsByEmail(database: any, email: string) {
        const docRef = query(collection(database, COLLECTION.DEVOTEES._NAME), where(COLLECTION.DEVOTEES.EMAIL, "==", email), limit(2));
        const querySnapshot = await getDocs(docRef);

        if (querySnapshot && !querySnapshot.empty) {
            if (querySnapshot.size > 1) {
                throw new Error(messages.ERROR_USER_MULTPLE_ENTRIES);
            } else {
                return querySnapshot;
            }
        } else {
            const newUser = {
                'phone_number': '',
                'email': email,
                'temple_memberships': [doc(firestore, 'temples', 'ZhLpyM9s6Uo9B8WwDzk4')]
            }
            await addNewUser(newUser, true);
        }
    }

    async function getTempleMembershipsDetails(database: any, templeMemberships: any) {
        const templeMembershipsDetails: Object[] = [];
        for (const templeRef of templeMemberships) {
            const temple:any = await getDataFromReference(templeRef);
            const membershipDetails = {
                _id: temple.id,
                ...temple.data()
            }
            templeMembershipsDetails.push(membershipDetails);
        }
        return templeMembershipsDetails;
    }

    const getUserMemberships = (devotee: { emailVerified: boolean, email: string, phoneNumber: string; }) => {
        if (devotee.emailVerified && devotee.email) {
            // User logged in through "Email", via any SSO like Google, etc
            setLoginMode(LOGIN_MODE.EMAIL);
            setInProgress(true);
            getDevoteeDetailsByEmail(firestore, devotee.email)
                .then(querySnapshot => {
                    if (querySnapshot) {
                        querySnapshot.forEach(document => {
                            // document.id and document.data() is never undefined for query doc snapshots
                            getTempleMemberships(devotee, document);
                        });
                    }
                })
                .catch(async (error) => {
                    // Handle and show all Errors
                    showToastMessage(error.message, 'error: ' + devotee.email,
                        undefined, 0, true);
                })
                .finally(() => setInProgress(false));
        } else if (devotee.phoneNumber) {
            // User logged in through "Phone Number", via any OTP/ password
            setLoginMode(LOGIN_MODE.PHONE);
            setInProgress(true);
            getDevoteeDetailsByPhoneNumber(firestore, devotee.phoneNumber)
                .then(querySnapshot => {
                    if (querySnapshot) {
                        querySnapshot.forEach(document => {
                            // document.id and document.data() is never undefined for query doc snapshots
                            getTempleMemberships(devotee, document);
                        });
                    }
                })
                .catch(error => {
                    // Handle and show all Errors
                    showToastMessage(error.message, 'error: ' + devotee.phoneNumber,
                        undefined, 0, true);
                })
                .finally(() => setInProgress(false));
        }
    }

    const getTempleMemberships = (devotee: any, document: any) => {
        // document.id and document.data() is never undefined for query doc snapshots
        if (document.id && document.data()) {
            let devoteeDetails = {
                ...devotee,
                _id: document.id,
                db: {...document.data()},
                templeMembershipsDetails: [{}]
            }
            let templeMemberships = document.data()[COLLECTION.DEVOTEES.TEMPLE_MEMBERSHIPS];
            if (!templeMemberships || (Array.isArray(templeMemberships) && templeMemberships.length <= 0)) {
                templeMemberships = [doc(firestore, 'temples', 'ZhLpyM9s6Uo9B8WwDzk4')];
                const newUserDb = devoteeDetails.db;
                _.set(newUserDb, COLLECTION.DEVOTEES.TEMPLE_MEMBERSHIPS, templeMemberships);
                devoteeDetails.db = newUserDb;
                saveUserProfile(newUserDb, document.id, true, true);
            }
            if (!_.get(devoteeDetails.db, COLLECTION.DEVOTEES.EMAIL_VERIFIED) && devotee.emailVerified && devotee.email) {
                const newUserDb = devoteeDetails.db;
                _.set(newUserDb, COLLECTION.DEVOTEES.EMAIL_VERIFIED, true);
                devoteeDetails.db = newUserDb;
                saveUserProfile(newUserDb, document.id, true, true);
            } else if (!_.get(devoteeDetails.db, COLLECTION.DEVOTEES.PHONE_NUMBER_VERIFIED) && devotee.phoneNumber) {
                const newUserDb = devoteeDetails.db;
                _.set(newUserDb, COLLECTION.DEVOTEES.PHONE_NUMBER_VERIFIED, true);
                devoteeDetails.db = newUserDb;
                saveUserProfile(newUserDb, document.id, true, true);
            }
            getTempleMembershipsDetails(firestore, templeMemberships)
                .then((templeMembershipsDetails) => {
                    devoteeDetails.templeMembershipsDetails = templeMembershipsDetails;
                    setUser(devoteeDetails);
                })
                .catch(error => showToastMessage(error.message + ' Please contact Admin.',
                    'Error fetching Memberships', undefined, 0, true))
                .finally(() => setInProgress(false));
        }
    }

    const logout = () => {
        // Sign out of Firebase.
        signOut(getAuth())
            .then(() => {
                navigate("/login");
            })
            .catch((error) => {
                // error during signOut
                showToastMessage(error.message);
            });
    }

    // Returns the signed-in user's display name.
    const getUserName = () => {
        const user = getAuth().currentUser;
        if (user) {
            return user.displayName;
        } else {
            return '';
        }
    }

    // Returns true if a user is signed-in.
    const isUserSignedIn = () => !!getAuth().currentUser;

    const showToastMessage = (message: string, summary?: string, severity?: ToastSeverityType, life?: number, sticky?: boolean) => {
        toast.current?.show({
            severity: severity || MessageSeverity.ERROR,
            summary: (summary || MessageSeverity.ERROR).toUpperCase(),
            detail: message,
            life: life || 3000,
            sticky
        });
    }

    const hideDialogueModal = () => {
        setModalContent(null);
    }

    const topMenuItems: MenuItem[] = [
        {
            label: 'Home',
            icon: 'pi pi-fw pi-home',
            url: '/'
        },
        {
            label: 'My Profile',
            icon: 'pi pi-fw pi-user-edit',
            command: () => { setModalContent(userProfileModalContent) }
        },
        {
            label: 'Devotees',
            icon: 'pi pi-fw pi-users',
            items: [
                {
                    label: 'Add New Devotee',
                    icon: 'pi pi-fw pi-user-plus',
                    command: () => { setModalContent(AddNewDevoteeModalContent) }
                },
                {
                    label: 'View Devotee',
                    icon: 'pi pi-fw pi-user',
                    command: () => { setModalContent(ViewDevoteeDetailsModalContent) }
                },
            ]
        }
    ];

    let userMenuItems: MenuItem[] = [
        {
            label: 'Add New Devotee', icon: 'pi pi-fw pi-user-plus',
            command: () => { setModalContent(AddNewDevoteeModalContent) }
        },
        {
            label: 'Edit My Profile', icon: 'pi pi-fw pi-user',
            command: () => { setModalContent(userProfileModalContent) }
        },
        { label: 'Settings', icon: 'pi pi-fw pi-cog', command: () => { setModalContent(settingsModalContent) } },
        { label: 'Log out', icon: 'pi pi-sign-out', className: "", command: () => { logout() } },
        { separator: true },
        {
            command: () => { alert('test') },
            template: (item, options) => {
                return (
                    <>
                        <img src={chantingAnimationGif} height="190"/>
                        <button onClick={(e) => options.onClick(e)} className={classNames(options.className, 'w-full p-link flex align-items-center')}>
                            {user && <Avatar image={user.photoURL || defaultProfilePic} className="mr-2" shape="circle" />}
                            <div className="flex flex-column align">
                                <span className="font-bold">{user && user.db? (user.db[COLLECTION.DEVOTEES.INITIATED_NAME] || '') : ''}</span>
                                <span className={user && user.db && user.db[COLLECTION.DEVOTEES.INITIATED_NAME]? '':'font-bold'}>
                                    {
                                        user && user.db? (user.db[COLLECTION.DEVOTEES.INITIATED_NAME]? 'aka. ':'') + (user.db[COLLECTION.DEVOTEES.NAME] || 'Deva')
                                        : 'Deva'}
                                </span>
                                <span className="text-sm">{user && user.db? (user.db[COLLECTION.DEVOTEES.PHONE_NUMBER] || ''):''}</span>
                            </div>
                        </button>
                    </>
                )
            }
        }
    ];

    const start = <img src={logo} className="App-logo mr-2" alt="logo" />;
    const end = <div className="card flex justify-content-center">
        {user && <Avatar image={user.photoURL || defaultProfilePic} size="large" shape="circle"
                onClick={e => userProfileActionsPanel.current.toggle(e)}/>}
        {/*<OverlayPanel ref={userProfileActionsPanel}>
            <img src={chantingAnimationGif} height="111"/>
            <h4>{user && user.name ? `${user.name.title || ''} ${user.name.firstName || ''} ${user.name.lastName || ''}`:'Deva'} 🙏</h4>
            <p>{user? user.phoneNumber:"deva"}</p>
            <Button id="btn-log-out" label="Log out" icon="pi pi-sign-out" className="has-margin p-button-danger" onClick={logout}/>
        </OverlayPanel>*/}
        <Menu model={userMenuItems} popup ref={userProfileActionsPanel} style={{ width: '250px' }}/>
    </div>;

    const saveUserProfile = (newUserDb: any, userID: string, self: boolean, saveInBackground?:boolean) => {
        const userRef = doc(firestore, COLLECTION.DEVOTEES._NAME, userID);
        setDoc(userRef, newUserDb, { merge: true })
            .then(() => {
                if (self) {
                    const newUser = user;
                    newUser.db = newUserDb;
                    setUser(newUser);
                }
                setModalContent(null);
                if (!saveInBackground) {
                    showToastMessage('Updated Successfully', `Profile`,
                        MessageSeverity.SUCCESS, 5000, false);
                }
            })
            .catch((error) => {
                console.log(error.message);
                if (!saveInBackground) {
                    showToastMessage(error.message, 'error',
                        undefined, 0, true);
                }
            });
    }

    const addNewUser = async (newUserDb: any, selfRegister?: boolean) => {
        if (inProgress) {
            return;
        }
        newUserDb[COLLECTION.DEVOTEES.TEMPLE_MEMBERSHIPS]= [doc(firestore, 'temples', 'ZhLpyM9s6Uo9B8WwDzk4')];
        // Add a new document which will generate a new id.
        setInProgress(true);
        let newDocID = null;
        if (selfRegister) {
            newDocID = newUserDb[COLLECTION.DEVOTEES.PHONE_NUMBER] + ';' + newUserDb[COLLECTION.DEVOTEES.EMAIL];
            console.log("New User with ID: " + newDocID + " is created.");
            try {
                await setDoc(doc(firestore, COLLECTION.DEVOTEES._NAME, newDocID), newUserDb);
                window.location.reload();
            }
            catch (error) {
                // do nothing
            }
            finally {
                setInProgress(false);
            }
        } else {
            const docRef = await addDoc(collection(firestore, COLLECTION.DEVOTEES._NAME), newUserDb);
            newDocID = docRef.id;
            showToastMessage('Added Successfully. Now, you can add more details if you want.', `Devotee ${newUserDb[COLLECTION.DEVOTEES.PHONE_NUMBER]}`,
                MessageSeverity.SUCCESS, 40000, false);
            const newUserProfileModalContent = {
                header: 'Edit Profile',
                content: (
                    <UserProfile userDB={newUserDb} userID={newDocID} self={selfRegister} submitAction={saveUserProfile} />
                )
            }
            setModalContent(newUserProfileModalContent);
        }
        setInProgress(false);
    }

    const getUser =  (newUserDb: any) => {
        const getDevoteeUserProfileModalContent = {
            header: 'Edit Profile',
            content: (
                <UserProfile userDB={newUserDb} userID={newUserDb.id} self={false} submitAction={saveUserProfile} />
            )
        }
        setModalContent(getDevoteeUserProfileModalContent);
    }
    const openAddNewUserModal =  () => {
        setModalContent(AddNewDevoteeModalContent)
    }

    const userProfileModalContent = {
        header: 'Edit Profile',
        content: (
            <UserProfile userDB={user && user.db} userID={user && user._id} self={true} submitAction={saveUserProfile}
                         templeMemberships={user && user.templeMembershipsDetails}
                         classMemberships={user && user.classMembershipDetails} />
        )
    }

    const settingsModalContent = {
        header: 'Settings',
        content: (
            <Settings userDB={user && user.db} userID={user && user._id} self={true} submitAction={saveUserProfile} />
        ),
        width: '45vw'
    }

    const newUserTemplate = {
        'phone_number': '',
        'name': ''
    }
    const existingUserTemplate = {
        'phone_number': ''
    }

    const AddNewDevoteeModalContent = {
        header: 'Add New Devotee',
        content: (
            <AddNewDevotee newUserDB={newUserTemplate} submitAction={addNewUser} />
        ),
        width: '50vw'
    }

    const ViewDevoteeDetailsModalContent = {
        header: 'View Devotee Details',
        content: (
            <ViewDevoteeDetails userDB={existingUserTemplate} submitAction={getUser} addNewDevotee={openAddNewUserModal}/>
        ),
        width: '25.5vw'
    }

    useEffect(() => {
        initFirebaseAuth();
    }, []);

    return (
        <div>
            <Menubar model={topMenuItems} start={start} end={end} />
            { inProgress ? <ProgressBar mode="indeterminate" style={{ height: '2px' }}></ProgressBar>: ''}
            <Toast ref={toast} position="bottom-left"/>
            <Dialog header={modalContent? modalContent.header:''}
                    visible={modalContent} style={{ width: modalContent? (modalContent.width || '90vw'):'90vw' }}
                    onHide={hideDialogueModal} footer={modalContent? modalContent.footer:''}>
                <span className="mb-5">
                    {modalContent? modalContent.content:''}
                </span>
            </Dialog>
            <h3 className="header-sticky-top center-absolute-horizontally"></h3>
            {
                user && <Outlet context={ [ user, setUser, setInProgress, showToastMessage, setModalContent ] }/>
            }
        </div>
    );
}

export default Home;