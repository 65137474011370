/**
 * To find your Firebase config object:
 *
 * 1. Go to your [Project settings in the Firebase console](https://console.firebase.google.com/project/_/settings/general/)
 * 2. In the "Your apps" card, select the nickname of the app for which you need a config object.
 * 3. Select Config from the Firebase SDK snippet pane.
 * 4. Copy the config object snippet, then add it here.
 */
const config = {
    apiKey: "AIzaSyCqcnuGUYpZCLQGtMrlS4QtN3gsogxSIek",
    authDomain: "devotee-association-dev.firebaseapp.com",
    projectId: "devotee-association-dev",
    storageBucket: "devotee-association-dev.appspot.com",
    messagingSenderId: "520626430862",
    appId: "1:520626430862:web:d106126b4cfea780f4fadf"
};

export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
        throw new Error('No Firebase configuration object provided. \nAdd your web app\'s configuration object to firebase-config.js');
    } else {
        return config;
    }
}