import './__styles__/App.scss';

import 'primereact/resources/themes/mdc-light-indigo/theme.css';   //theme
import 'primereact/resources/primereact.min.css';                  //core css
import 'primeicons/primeicons.css';                                //icons
import "primeflex/primeflex.css";

import React from 'react';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import {initializeApp} from "firebase/app";
import {getFirebaseConfig} from "../lib/firebase-config";
import {Error404, Home, Login} from "./index";
import {Footer} from "../components";
import Temples from "./Temples";
import TempleDetails from "./TempleDetails";

// Initialize Firebase
const firebaseApp = initializeApp(getFirebaseConfig());

function App() {
    return (
        <div className="App">
            <section className="App-content">
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={< Home />}>
                            <Route index element={<Temples />} />
                            <Route path="/temple/:templeID" element={<TempleDetails />} />
                        </Route>
                        <Route path='/login' element={< Login />}></Route>
                        <Route path='*' element={< Error404 />}></Route>
                    </Routes>
                </BrowserRouter>
            </section>
            <Footer />
        </div>
    );
}

export default App;
