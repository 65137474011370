export const DEVELOPER_NAME = "Vivek Chandra";
export const LOCAL_STORAGE_VARS = {
    PHONE_NUMBER: "phone_number"
}

export const LOGIN_MODE = {
    EMAIL: "email",
    PHONE: "phone"
}

export const COLLECTION = {
    DEVOTEES: {
        _NAME: "devotees",
        NAME: "name",
        EMAIL: "email",
        EMAIL_VERIFIED: "email_verified",
        INITIATED_NAME: "initiated_name",
        PHONE_NUMBER: "phone_number",
        PHONE_NUMBER_VERIFIED: "phone_number_verified",
        GENDER: "gender",
        MARITAL_STATUS: "marital_status",
        DOB: "date_of_birth",
        LANG_PREF: "language_preference",
        OCCUPATION: "occupation",
        ADDRESS: "address",
        SKILLS: "skills",
        COUNSELLOR_ID: "counsellor_id",
        TEMPLE_MEMBERSHIPS: "temple_memberships",
        CLASS_MEMBERSHIPS: "class_memberships",
        WHATSAPP_CONSENT: "whatsapp_consent",
        WHATSAPP_NUMBER: "whatsapp_phone_number",
        SOURCE: "source",
        FAMILY_ID: "family_id",
        CREATION_TIMESTAMP: "creation_timestamp",
        UPDATE_TIMESTAMP: "update_timestamp"
    },
    TEMPLES: {
        _NAME: "temples",
        NAME: "name",
        DEITY_NAME: "deity_name",
        INTRO: "intro",
        WEBSITE_URL: "website_url",
        ADMINS: "admins"
    },
    FAMILIES: {
        _NAME: "families",
        MEMBERS: "members"
    },
    CLASSES: {
        _NAME: "classes",
        NAME: "name",
        LEADERS: "leaders",
        VOLUNTEERS: "volunteers",
        MEMBERS: "members"
    },
    ATTENDANCE_RECORDS: {
        _NAME: "attendance_records",
        DATE: "date",
        MEMBERS_PRESENT: "members_present"
    }
}

export const FIELDS = {
    ADDRESS: {
        CITY: "city",
        COUNTRY: "country",
        LINE1: "line1",
        LINE2: "line2",
        SOCIETY: "society",
        AREA: "areas",
        PINCODE: "pincode",
        STATE: "state",
        GMAP_URL: "gmap_url"
    }
}
